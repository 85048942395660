<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
    page() {
        return {
            title: this.title,
            meta: [{ name: "description" }],
        };
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Starter page",
            items: [
                {
                    text: "Utility",
                    href: "/",
                },
                {
                    text: "Starter page",
                    active: true,
                },
            ],
            loading: false,
            appid: "",
            userinfo: [],
            chargeactive: [],
            register: {},
        };
    },
    mounted() {
        this.userinfo = JSON.parse(localStorage.getItem("user"));
        this.appid = this.$route.params.appid;
        this.title = "注册设置";
        this.items = [
            {
                text: this.$t("menuitems.micoapp.text"),
                href: "/",
            },
            {
                text: this.$t("menuitems.icp.micoapp.list"),
                href: "/micoapp",
            },
            {
                text: this.$t("menuitems.icp.micoapp.info"),
                href: `/micoapp/${this.appid}`,
            },
            {
                text: "注册设置",
                active: true,
            },
        ];
        this.getmicoappmenu();
    },
    methods: {
        getmicoappmenu() {
            var that = this;
            that.loading = true;
            that.$axios
                .post(
                    "https://api.aicoiot.com/micoappapi.php",
                    {
                        action: "micoapp.setting",
                        appid: that.$route.params.appid,
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.loading = false;
                    that.register = JSON.parse(response.data.data.register);
                })
                .catch(function (error) {
                    that.loading = false;
                    that.$notify.error({
                        title: '请求错误',
                        message: error
                    });
                });
        },
        save_micoappsetting(type) {
            var that = this;
            let postdata = that[type];
            that.loading = true;
            if (postdata) {
                that.$axios
                    .post(
                        "https://api.aicoiot.com/micoappapi.php",
                        {
                            action: "micoapp.savesetting",
                            appid: that.$route.params.appid,
                            type: type,
                            datas: postdata
                        },
                        {
                            headers: { Openid: that.userinfo.openid },
                        }
                    )
                    .then(function (response) {
                        that.loading = false;
                        if (response.data.status == 200) {
                            that.$message.success({
                                message: "保存成功"
                            });
                            that.getmicoappmenu();
                        } else {
                            that.$message.error({
                                message: response.data.message
                            });
                        }
                    })
                    .catch(function (error) {
                        that.loading = false;
                        that.$notify.error({
                            title: '请求错误',
                            message: error
                        });
                    });

            } else {
                that.$message.error({
                    message: "提交数据不存在"
                });
            }
        }
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" v-loading="loading">
                        <el-form ref="form" :model="register" label-width="120px">
                            <el-form-item label="微信快捷登录">
                                <el-switch v-model="register.isOauthMpweixin" active-text="开启"
                                    inactive-text="关闭" :active-value="1" :inactive-value="0">
                                </el-switch>
                            </el-form-item>
                            <el-form-item>
                                <el-button @click="save_micoappsetting('register')" type="primary">保存</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>